<div>
  <!-- *ngIf="companyId == 'O-1670918559523'" -->
  <button
    (click)="openChatbotModal()"
    class="chatbot-toggle"
    color="transparent"
    mat-fab
    type="button"
  >
    <mat-icon class="w-fit h-full" svgIcon="sidebar:chatbot-img"></mat-icon>
  </button>

  <button
    (click)="openConfig.emit()"
    class="config-panel-toggle"
    color="primary"
    mat-fab
    type="button"
  >
    <mat-icon svgIcon="mat:settings"></mat-icon>
  </button>
</div>

<ng-template #chatbotModal>
  <div class="h-full flex flex-col justify-between">
    <mat-toolbar class="flex justify-between items-center pt-5 pb-5 bg-primary">
      <div class="flex items-center">
        <div class="flex justify-center mb-1">
          <mat-icon class="w-10 h-10" svgIcon="sidebar:chatbot-img"></mat-icon>
        </div>
        <h3 class="text-white mx-2">iSaned Bot</h3>
      </div>

      <div>
        <button
          class="bg-primary w-7 h-7 mb-1 rounded-[8px] text-white"
          (click)="minimizeChatbot()"
          mat-icon-button
          type="button"
        >
          <mat-icon svgIcon="mat:remove"></mat-icon>
        </button>
        <button
          class="bg-primary w-7 h-7 mb-1 rounded-[8px] text-white mx-2"
          (click)="toggleFullScreen()"
          mat-icon-button
          type="button"
        >
          <mat-icon
            [svgIcon]="!isFullScreen ? 'mat:fullscreen' : 'mat:fullscreen_exit'"
          ></mat-icon>
        </button>
        <button
          class="bg-primary w-7 h-7 mb-1 rounded-[8px] text-white"
          (click)="closeDialog()"
          mat-icon-button
          type="button"
        >
          <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
      </div>
    </mat-toolbar>

    <!-- Welcome Message -->
    <mat-dialog-content
      *ngIf="!showMessages"
      class="p-0 pr-[20px] pl-[20px] h-full"
    >
      <div class="flex flex-col justify-center align-middle">
        <div class="flex justify-center mb-1">
          <mat-icon class="w-12 h-12" svgIcon="sidebar:chatbot-img"></mat-icon>
        </div>
        <div class="bg-[#F5F9FF] text-[#05142E] p-2">
          <h3 class="font-bold">{{ "Chatbot.Chatbot.welcome" | translate }}</h3>
          <p class="font-bold">
            {{ "Chatbot.Chatbot.Title" | translate }}
          </p>

          <ul class="leading-6 mt-1">
            <li>
              {{ "Chatbot.Chatbot.stepOne" | translate }}
            </li>
            <li>
              {{ "Chatbot.Chatbot.stepTwo" | translate }}
            </li>
            <li>
              {{ "Chatbot.Chatbot.stepThree" | translate }}
            </li>
            <li>
              {{ "Chatbot.Chatbot.stepFour" | translate }}
            </li>
            <li>
              {{ "Chatbot.Chatbot.stepFive" | translate }}
            </li>
            <li>
              {{ "Chatbot.Chatbot.stepSix" | translate }}
            </li>
            <li>
              {{ "Chatbot.Chatbot.stepSeven" | translate }}
            </li>
          </ul>
          <br />
          <p>
            <strong>" {{ "Chatbot.Chatbot.pleaseNote" | translate }}</strong>
            {{ "Chatbot.Chatbot.note" | translate }} "
          </p>
        </div>
      </div>
    </mat-dialog-content>

    <div
      class="flex justify-center items-center mb-3 mt-3"
      *ngIf="!showMessages"
    >
      <button mat-button class="bg-primary text-white" (click)="startChat()">
        {{ "Chatbot.Chatbot.startChat" | translate }}
      </button>
    </div>

    <mat-dialog-content *ngIf="showMessages">
      <div class="flex flex-col justify-between">
        <div
          class="flex flex-col h-full w-full bg-white rounded-lg p-2 overflow-auto"
          #scrollMe
        >
          <div class="flex w-full mt-2 space-x-3">
            <div
              class="flex-shrink-0 h-10 w-10 rounded-full bg-transparent chat-icon"
            >
              <img src="assets/img/resources/chatbot-img.svg" />
            </div>
            <div class="flex-col flex">
              <div
                class="bg-[#F5F9FF] text-[#05142E] p-3 rounded-r-lg rounded-bl-lg"
              >
                <div>
                  <strong>{{ "Chatbot.Chatbot.welcome" | translate }}</strong>
                  <p>
                    {{ "Chatbot.Chatbot.helpMsg" | translate }}
                  </p>
                </div>
              </div>
              <div class="mt-2 flex flex-wrap" *ngIf="!userSelectLayer">
                <span
                  class="cursor-pointer p-1 m-1 text-center border-2 border-[#D0D5DD] rounded-[5px]"
                  (click)="chooseLayer(layer)"
                  *ngFor="let layer of layers"
                >
                  {{ "Chatbot.Layers." + layer | translate }}
                </span>
              </div>
            </div>
          </div>

          <div
            class="flex flex-col flex-grow"
            *ngFor="let message of messages; index as i"
          >
            <!-- user message -->
            <div class="flex w-full mt-2 space-x-3 justify-end">
              <div class="flex">
                <div
                  class="bg-[#F2F4F7] text-white p-3 rounded-l-lg rounded-br-lg"
                >
                  <p class="text-sm text-[#05142E]">
                    {{ message.question }}
                  </p>
                </div>
              </div>
              <div class="flex-shrink-0 h-10 w-10 rounded-full">
                <ngx-avatars
                  size="40"
                  [src]="loggedUser.picture"
                  [name]="loggedUser.fullName"
                  [textSizeRatio]="2"
                  [round]="true"
                  [initialsSize]="2"
                  [style]="customStyle"
                >
                </ngx-avatars>
              </div>
            </div>

            <!-- chat message -->
            <div class="flex w-full mt-2 space-x-3">
              <div
                class="flex-shrink-0 h-10 w-10 rounded-full bg-transparent chat-icon"
              >
                <img src="assets/img/resources/chatbot-img.svg" />
              </div>
              <div class="flex flex-col">
                <div
                  class="bg-[#F5F9FF] text-[#05142E] p-3 rounded-r-lg rounded-bl-lg"
                >
                  <pre
                    class="text-sm whitespace-pre-line"
                    style="font-family: 'Inter var'"
                    dir="auto"
                    *ngIf="message?.message; else loader"
                  >
                  {{ message.message }}
              </pre
                  >
                  <ng-template #loader>
                    <div class="loader"></div>
                  </ng-template>
                </div>

                <div class="flex justify-end mt-2">
                  <img
                    *ngIf="message.message"
                    class="mx-1 cursor-pointer h-fit p-1 border-2 border-solid border-[#D0D5DD] rounded-[10px]"
                    [matTooltip]="'Copy'"
                    src="assets/img/resources/copy-icon.svg"
                    (click)="copyMessage(message.message)"
                  />

                  <img
                    *ngIf="
                      message.message &&
                      (message?.isItLiked || message.isItLiked == null)
                    "
                    class="mx-1 h-fit p-1 border-2 border-solid border-[#D0D5DD] rounded-[10px]"
                    [ngClass]="!message.isItLiked ? 'cursor-pointer' : ''"
                    [matTooltip]="'like'"
                    [src]="
                      message.isItLiked
                        ? 'assets/img/resources/like-fill-icon.svg'
                        : 'assets/img/resources/like-message.svg'
                    "
                    (click)="
                      message.isItLiked == null ? likeMessage(message, i) : ''
                    "
                  />

                  <img
                    *ngIf="
                      message.message &&
                      (!message?.isItLiked || message.isItLiked == null)
                    "
                    class="mx-1 h-fit p-1 border-2 border-solid border-[#D0D5DD] rounded-[10px]"
                    [ngClass]="
                      message.isItLiked != false ? 'cursor-pointer' : ''
                    "
                    [matTooltip]="'dislike'"
                    [src]="
                      message.isItLiked == false
                        ? 'assets/img/resources/dislike-fill-icon.svg'
                        : 'assets/img/resources/dislike-message.svg'
                    "
                    (click)="
                      message.isItLiked == null
                        ? dislikeMessage(message, i)
                        : ''
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-divider
      class="border-t-[#D0D5DD] border-t-2"
      *ngIf="showMessages"
    ></mat-divider>

    <!-- message input -->
    <div
      class="flex items-center pt-2 pl-3 pr-3 mb-3 bg-[#FCFCFD]"
      *ngIf="showMessages"
    >
      <input
        class="h-10 pl-2 pr-2 w-full rounded-[10px] border border-[#D0D5DD] text-sm"
        type="text"
        dir="auto"
        [placeholder]="'Chatbot.MsgPlaceholder' | translate"
        [disabled]="!userSelectLayer"
        (keyup.enter)="sendMessage(messageInput.value)"
        #messageInput
      />
      <button
        class="bg-primary w-10 h-10 mx-1 rounded-[8px] text-white"
        mat-icon-button
        type="button"
        [disabled]="disableSend || !messageInput.value"
        (click)="sendMessage(messageInput.value)"
      >
        <mat-icon svgIcon="sidebar:send"></mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #rateMessage>
  <div class="flex m-0" mat-dialog-title>
    <h1>{{ "Chatbot.Chatbot.feedbackTitle" | translate }}</h1>
  </div>
  <div mat-dialog-content>
    <div class="flex flex-col justify-center items-center">
      <p>
        {{ "Chatbot.Chatbot.feedbackBody" | translate }}
      </p>
      <p>
        {{ "Chatbot.Chatbot.feedbackBody2" | translate }}
      </p>
      <div class="w-full text-center">
        <textarea
          class="rounded-[10px] border border-[#000] mt-2 p-2 w-[95%]"
          rows="5"
          [placeholder]="'Chatbot.ShareFeedback' | translate"
          [(ngModel)]="userFeedBackMessage"
        ></textarea>
      </div>
      <div class="flex justify-end w-full p-2">
        <app-basic-button
          mat-button
          [btnType]="'Add'"
          [btnText]="'Submit'"
          (click)="closeFeedBackModal()"
        >
        </app-basic-button>
      </div>
    </div>
  </div>
</ng-template>
