import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map, startWith, takeUntil } from 'rxjs';
import { fadeInRight400ms } from 'src/@hodhod/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { scaleIn400ms } from 'src/@hodhod/animations/scale-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { ManageAccessControlService } from 'src/backend/services/manage-access-control/manage-access-control.service';
import { InviteEmployeeComponent } from '../invite-employee/invite-employee.component';

@UntilDestroy()
@Component({
  selector: 'app-employees-list',
  templateUrl: './employees-list.component.html',
  styleUrls: ['./employees-list.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms,
    scaleFadeIn400ms,
    scaleFadeIn400ms,
    scaleIn400ms,
    fadeInRight400ms,
  ],
})
export class EmployeesListComponent extends BaseComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('resendInviteModal') resendInviteModal!: TemplateRef<any>;

  columns: TableColumn<any>[] = [
    { label: 'Name', property: 'fullName', type: 'text', visible: true },
    {
      label: 'Email',
      property: 'email',
      type: 'text',
      visible: true,
    },
    {
      label: 'DevicesCount',
      property: 'devicesCount',
      type: 'text',
      visible: true,
    },
    {
      label: 'LicenseCount',
      property: 'licenseCount',
      type: 'text',
      visible: true,
    },
    {
      label: 'UserStatus',
      property: 'userStatus',
      type: 'boolean',
      visible: true,
    },
    {
      label: 'InvitationStatus',
      property: 'lastInvitationStatus',
      type: 'boolean',
      visible: true,
    },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public sectionStateCompanyLicense: SectionStateStatus =
    SectionStateStatus.Ready;
  searchCtrl = new UntypedFormControl();
  searchValue: string = '';
  timer = null;
  pageSizeOptions: number[] = [5, 10];
  pagination = {
    pageIndex: 0,
    pageSize: 5,
    length: 0,
  };
  currentQueryParams = null;
  filteredParams = null;
  addUserControl = new FormControl('', Validators.required);
  dataSource = new MatTableDataSource<any>([]);
  isAddUser: boolean = false;
  manageUsersAccessList: any = [];
  companyLicenseData: any = null;
  translationsList: any = {};

  cards: any = [];
  selectedCard: any = null;
  selectedUser: any = '';
  isIssueCard: boolean = false;

  hasCreatePermission = ApplicationPermission.ACCESS_CONTROL_CREATE;

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private feedBackService: AsyncFeedbackService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private manageAccessControlService: ManageAccessControlService
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  override ngOnInit(): void {
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.sort = this.sort;
    this.currentQueryParams = this.route.snapshot.queryParams;

    this.pagination.pageIndex = this.currentQueryParams?.pageIndex
      ? Number(this.currentQueryParams?.pageIndex)
      : 0;
    this.pagination.pageSize = this.currentQueryParams?.pageSize
      ? Number(this.currentQueryParams?.pageSize)
      : 5;

    this.searchCtrl.setValue(this.currentQueryParams?.searchValue);

    this.sort.active = this.currentQueryParams?.sortName;
    this.sort.direction = this.currentQueryParams?.sortDirection;
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        sortName: res.active,
        sortDirection: res.direction,
        pageIndex: 0,
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.currentQueryParams,
      });
    });

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));

    this.getUsersWithAccessControl();
    this.getCards();
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  getCards() {
    this.sectionState = SectionStateStatus.Loading;
    this.manageAccessControlService.getCards().subscribe({
      next: (res) => {
        this.cards = res;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (error) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  getUsersWithAccessControl() {
    this.filteredParams = {
      sortDirection: this.currentQueryParams?.sortDirection || '',
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
      searchValue: this.currentQueryParams?.searchValue
        ? this.currentQueryParams?.searchValue
        : this.searchValue,
    };

    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.manageAccessControlService
      .getUsersWithAccessControl(this.filteredParams)
      .subscribe({
        next: (res) => {
          this.dataSource = new MatTableDataSource(res?.data);
          this.pagination.length = res?.totalCount;
          this.sectionState = SectionStateStatus.Ready;
        },
        error: (error) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
  }

  addUser() {
    this.dialog
      .open(InviteEmployeeComponent, {
        height: 'auto',
        width: '60%',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res.created) {
          this.getUsersWithAccessControl();
        }
      });
  }

  onAddUser() {
    const data = {
      userId: this.addUserControl.value['id'],
    };
    this.loadingService.startLoading(true);
    this.manageAccessControlService.createUser(data).subscribe({
      next: (res) => {
        this.getUsersWithAccessControl();
        this.manageAccessControlService.triggerLicenseDataUpdate();
        this.addUserControl.setValue('');
        this.loadingService.stopLoading();
        this.closeDialog();
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, res?.message)
        );
      },
      error: (error) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  viewUserAccess(userId: string) {
    this.router.navigate([
      SharedConstants.MANAGE_ACCESS_CONTROL +
        '/' +
        SharedConstants.MANAGE_USERS_ACCESS_CONTROL_USER_DETAILS +
        '/' +
        userId,
    ]);
  }

  cancelInvitation(userId: string) {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.manageAccessControlService.cancelInvitation(userId).subscribe({
            next: (res) => {
              this.getUsersWithAccessControl();
              this.manageAccessControlService.triggerLicenseDataUpdate();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
            },
            error: (error) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  onCardSelect(partNumber: string) {
    this.selectedCard = partNumber;
  }

  onResendInvitationClick(userId: string) {
    this.selectedCard = null;
    this.selectedUser = '';
    this.isIssueCard = false;
    this.dialog.open(this.resendInviteModal, {
      width: '60%',
      height: 'auto',
    });
    this.selectedUser = userId;
  }

  resendInvitation() {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          // this.sectionState = SectionStateStatus.LoadingTransparent;
          this.loadingService.startLoading(true);
          const data = {
            userId: this.selectedUser,
            partNumber: this.selectedCard,
          };
          this.manageAccessControlService.resendInvitation(data).subscribe({
            next: (res) => {
              this.getUsersWithAccessControl();
              this.closeDialog();
              this.manageAccessControlService.triggerLicenseDataUpdate();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.loadingService.stopLoading();
            },
            error: (error) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();

              // this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  resendOTP(userId: string) {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.manageAccessControlService.resendOTP(userId).subscribe({
            next: (res) => {
              this.getUsersWithAccessControl();
              this.manageAccessControlService.triggerLicenseDataUpdate();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
            },
            error: (error) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  deleteUser(userId: string) {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.manageAccessControlService.deleteUser(userId).subscribe({
            next: (res) => {
              this.getUsersWithAccessControl();
              this.manageAccessControlService.triggerLicenseDataUpdate();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
            },
            error: (error) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  handlePageEvent(page) {
    this.pagination.pageIndex = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pagination.length = page.length;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getUsersWithAccessControl();
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      searchValue: this.searchValue,
      pageIndex: 0,
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.pagination.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getUsersWithAccessControl();
    }, 700);
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
