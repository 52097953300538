import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { ReportingDashboardService } from 'src/backend/services/dashboards/reporting-dashboard.service';
import {
  AnalogIotDashboard,
  Dashboard,
  DashboardFilterPayload,
  DashboardSetting,
  DigitalIotDashboard,
  IotDashboardSetting,
} from '../../models/dashboard';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DashboardFullscreenComponent } from '../dashboard-fullscreen/dashboard-fullscreen.component';
import {
  DashboardColors,
  DashboardTypes,
  IoTFilterDashboards,
  PenaltyFilterDashboards,
  SmartSafetyFilterDashboards,
} from 'src/@hodhod/common/enum';
import { EditDashboardTargetPopupComponent } from 'src/app/dashboard/components/edit-dashboard-target-popup/edit-dashboard-target-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { DashboardTargetService } from 'src/backend/services/dashboard-target/dashboard-target.service';
import { DashboardCalenderComponent } from 'src/app/dashboard/components/dashboard-calender/dashboard-calender.component';
import { DatePipe } from '@angular/common';
import { IotDashboardService } from 'src/backend/services/dashboards/iot-dashboard.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
@Component({
  selector: 'app-dashboard-card-two',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss'],
})
export class DashboardCardComponent implements OnInit, OnChanges {
  @Input() dashboardSetting: DashboardSetting;
  @Input() iotdashboardSetting: IotDashboardSetting;
  @Input() isIot: boolean;

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public loadingLabel: string = '';
  public openCalender: boolean = false;
  public filterDashboards: any = null;
  public penaltyFilterDashboards: any = null;
  public iotFilterType: string = 'Day';
  hasIotReadPermission = ApplicationPermission.IOT_DASHBOARD_ACCESS;
  public dashboard: Dashboard;
  public optimizedDashboard: any;
  public dashboardFilter: DashboardFilterPayload = {
    fromDate: new Date().toISOString(),
    toDate: new Date().toISOString(),
  };

  public chartLevel = 'one';

  public dashboardColors: any = null;
  public dashboardTypes: any = null;

  public chartData: any;
  public chart: any;

  public analogDashboard: AnalogIotDashboard;
  public digitalDashboard: DigitalIotDashboard;
  public visibleDigitalParameters: any;

  public auditPassValue: string = '';

  public translationsList: any = {};
  private destroy$ = new Subject();

  hasUpdateDashboardTargetPermission =
    ApplicationPermission.DASHBOARD_TARGET_UPDATE;

  constructor(
    private reportingDashboardService: ReportingDashboardService,
    private feedBackService: AsyncFeedbackService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private dashboardTargetService: DashboardTargetService,
    private iotDashboardService: IotDashboardService,
    private _bottomSheet: MatBottomSheet
  ) {
    this.translate
      .get(['Dashboards'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit(): void {
    this.filterDashboards = this.isIot
      ? IoTFilterDashboards
      : SmartSafetyFilterDashboards;
    this.penaltyFilterDashboards = PenaltyFilterDashboards;
    this.dashboardColors = DashboardColors;
    this.dashboardTypes = DashboardTypes;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dashboardSetting']?.currentValue) {
      if (this.dashboardSetting.filterName === 'customRange') {
        this.dashboardFilter.fromDate =
          this.dashboardSetting.fromDate.toString();
        this.dashboardFilter.toDate = this.dashboardSetting.toDate.toString();
        this.getDashboardData();
      } else {
        this.setFilterForDashboard(this.dashboardSetting.filterName);
      }
    }
    if (changes['iotdashboardSetting']?.currentValue) {
      if (this.iotdashboardSetting.filterName === 'CustomRange') {
        this.dashboardFilter.fromDate =
          this.iotdashboardSetting.filteredFrom.toString();
        this.dashboardFilter.toDate =
          this.iotdashboardSetting.filteredTo.toString();
        if (this.iotdashboardSetting.isAnalog) {
          this.getAnalogDashboard();
        } else {
          this.getDigitalDashboard();
        }
      } else {
        this.setFilterForDashboard(this.iotdashboardSetting.filterName);
      }
    }
  }

  setFilterForDashboard(filterName: string, changeFilter?: boolean) {
    const date = new Date();

    if (filterName !== 'customRange') {
      if (!this.isIot) {
        this.dashboardSetting.filterName = filterName;
      } else {
        this.iotdashboardSetting.filterName = filterName;
      }
      if (filterName === 'CurrentHour') {
        const currentHour = new Date();
        currentHour.setMinutes(0);
        currentHour.setSeconds(0);
        currentHour.setMilliseconds(0);
        const endOfCurrentHour = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          59,
          59,
          0
        );
        this.dashboardFilter.fromDate = new Date(currentHour).toISOString();
        this.dashboardFilter.toDate = new Date(endOfCurrentHour).toISOString();
        this.iotFilterType = 'Min';
      } else if (filterName === 'LastHour') {
        const now = new Date();
        const beginningOfPreviousHour = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          now.getHours() - 1,
          0,
          0,
          0
        );
        const endOfLastHour = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          now.getHours() - 1,
          59,
          59,
          999
        );
        this.dashboardFilter.fromDate = new Date(
          beginningOfPreviousHour
        ).toISOString();
        this.dashboardFilter.toDate = new Date(endOfLastHour).toISOString();
        this.iotFilterType = 'Min';
      } else if (filterName === 'thisDay' || filterName === 'Today') {
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);

        let lastHourInDay = new Date();
        lastHourInDay.setHours(23);
        lastHourInDay.setMinutes(59);
        lastHourInDay.setSeconds(59);
        lastHourInDay.setMilliseconds(999);

        this.dashboardFilter.fromDate = new Date(today).toISOString();
        this.dashboardFilter.toDate = new Date(lastHourInDay).toISOString();
        this.iotFilterType = 'Hour';
      } else if (filterName === 'yesterday' || filterName === 'Yesterday') {
        const today = new Date();
        const day = today.getDate() - 1;
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setDate(day);

        this.dashboardFilter.fromDate = new Date(today).toISOString();
        today.setHours(23);
        today.setMinutes(59);
        today.setSeconds(59);
        today.setMilliseconds(999);
        this.dashboardFilter.toDate = new Date(today).toISOString();
        this.iotFilterType = 'Hour';
      } else if (
        filterName === 'currentMonth' ||
        filterName === 'CurrentMonth'
      ) {
        const today = new Date();
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        firstDay.setHours(0, 0, 0, 0);
        today.setHours(23, 59, 59, 0);
        this.dashboardFilter.fromDate = firstDay.toISOString();
        this.dashboardFilter.toDate = today.toISOString();
        this.iotFilterType = 'Day';
      } else if (filterName === 'lastMonth' || filterName === 'LastMonth') {
        const today = new Date();
        const firstDayPrevMonth = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        firstDayPrevMonth.setHours(0, 0, 0, 0);
        this.dashboardFilter.fromDate = firstDayPrevMonth.toISOString();
        const lastDayPrevMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );
        lastDayPrevMonth.setHours(23, 59, 59, 0);
        this.dashboardFilter.toDate = lastDayPrevMonth.toISOString();
        this.iotFilterType = 'Day';
      } else if (
        filterName === 'lastThirtyDay' ||
        filterName === 'Past30Days'
      ) {
        const today = new Date();
        today.setHours(23, 59, 59, 0);
        this.dashboardFilter.toDate = new Date(today).toISOString();
        const Past30Day = new Date(today.getTime() - 29 * 24 * 60 * 60 * 1000);
        Past30Day.setHours(0, 0, 0, 0);
        this.dashboardFilter.fromDate = Past30Day.toISOString();
        this.iotFilterType = 'Day';
      } else if (filterName === 'yearToDate') {
        const today = new Date();
        today.setHours(23, 59, 59, 0);
        this.dashboardFilter.toDate = new Date(today).toISOString();
        const startYearDate = new Date(date.getFullYear(), 0, 1);
        startYearDate.setHours(0, 0, 0, 0);
        this.dashboardFilter.fromDate = startYearDate.toISOString();
        this.iotFilterType = 'Day';
      } else if (filterName === 'lastYear') {
        const today = new Date();
        today.setHours(23, 59, 59, 0);
        this.dashboardFilter.toDate = new Date(today).toISOString();
        const startDateBefore12Months = new Date(today);
        startDateBefore12Months.setFullYear(
          startDateBefore12Months.getFullYear() - 1
        );
        startDateBefore12Months.setHours(0, 0, 0, 0);
        this.dashboardFilter.fromDate = startDateBefore12Months.toISOString();
        this.iotFilterType = 'Day';
      } else if (filterName === 'lastNineMonths') {
        const today = new Date();
        today.setHours(23, 59, 59, 0);
        this.dashboardFilter.toDate = new Date(today).toISOString();
        const startDateBefore9Months = new Date(today);
        startDateBefore9Months.setMonth(startDateBefore9Months.getMonth() - 9);
        startDateBefore9Months.setHours(0, 0, 0, 0);
        this.dashboardFilter.fromDate = startDateBefore9Months.toISOString();
        this.iotFilterType = 'Day';
      } else if (filterName === 'lastSixMonths') {
        const today = new Date();
        today.setHours(23, 59, 59, 0);
        this.dashboardFilter.toDate = new Date(today).toISOString();
        const startDateBefore6Months = new Date(today);
        startDateBefore6Months.setMonth(startDateBefore6Months.getMonth() - 6);
        startDateBefore6Months.setHours(0, 0, 0, 0);
        this.dashboardFilter.fromDate = startDateBefore6Months.toISOString();
        this.iotFilterType = 'Day';
      } else if (filterName === 'lastThreeMonths') {
        const today = new Date();
        today.setHours(23, 59, 59, 0);
        this.dashboardFilter.toDate = new Date(today).toISOString();
        const startDateBefore3Months = new Date(today);
        startDateBefore3Months.setMonth(startDateBefore3Months.getMonth() - 3);
        startDateBefore3Months.setHours(0, 0, 0, 0);
        this.dashboardFilter.fromDate = startDateBefore3Months.toISOString();
        this.iotFilterType = 'Day';
      }

      if (this.isIot) {
        changeFilter
          ? this.updateFilterForIot()
          : this.iotdashboardSetting.isAnalog
          ? this.getAnalogDashboard()
          : this.getDigitalDashboard();
      } else {
        this.dashboardSetting.fromDate = new Date(
          this.dashboardFilter.fromDate
        );
        this.dashboardSetting.toDate = new Date(this.dashboardFilter.toDate);
        if (changeFilter) {
          this.saveFilterData();
        } else {
          this.getDashboardData();
        }
      }
    }
  }

  updateFilterForIot() {
    this.sectionState = SectionStateStatus.Loading;
    const data = {
      dashboardID: this.iotdashboardSetting.dashboardID,
      filterName: this.iotdashboardSetting.filterName,
      filteredFrom: this.dashboardFilter.fromDate,
      filteredTo: this.dashboardFilter.toDate,
    };
    this.iotDashboardService.updateFilterForIot(data).subscribe({
      next: (res) => {
        if (this.iotdashboardSetting.isAnalog) {
          this.getAnalogDashboard();
        } else {
          this.getDigitalDashboard();
        }
      },
      error: (error) => {
        this.feedBackService?.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  getAnalogDashboard() {
    this.sectionState = SectionStateStatus.Loading;
    const data = {
      dashboardID: this.iotdashboardSetting.dashboardID,
      filteredFrom: this.dashboardFilter.fromDate,
      filteredTo: this.dashboardFilter.toDate,
      filter: this.iotFilterType,
    };
    this.iotDashboardService.getAnalogDashboard(data).subscribe({
      next: (res) => {
        this.analogDashboard = res;
        this.optimizeAnalogDashboard(res);
      },
      error: (error) => {
        this.feedBackService?.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  getDigitalDashboard() {
    const data = {
      dashboardID: this.iotdashboardSetting.dashboardID,
      filteredFrom: this.dashboardFilter.fromDate,
      filteredTo: this.dashboardFilter.toDate,
    };
    this.sectionState = SectionStateStatus.Loading;
    this.iotDashboardService.getDigitalDashboard(data).subscribe({
      next: (res) => {
        this.digitalDashboard = res;
        this.visibleDigitalParameters = this.digitalDashboard.digitalReadings
          .filter((item) => item.isVisible)
          .map((el) => el.parameterID);
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (error) => {
        this.feedBackService?.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  updateDigitalParameters() {
    this.sectionState = SectionStateStatus.Loading;
    const data = {
      dashboardID: this.iotdashboardSetting.dashboardID,
      machineParametersIDs: this.visibleDigitalParameters,
    };
    this.iotDashboardService.updateDigitalParameters(data).subscribe({
      next: (res) => {
        this.getDigitalDashboard();
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (error) => {
        this.feedBackService?.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  optimizeAnalogDashboard(analogDashboard: AnalogIotDashboard) {
    const chartData = {
      chartTitle: this.iotdashboardSetting.dashboardName,
      chartColor: '#3A9E21',
      chartType: 'line',
      xValues: [],
      lowerRange: analogDashboard.lowerControlLimit,
      upperRange: analogDashboard.upperControlLimit,
      unit: analogDashboard.unit,
      chartData: [],
    };

    chartData.xValues =
      this.iotFilterType !== 'Min'
        ? analogDashboard.analogReadings.map((item) => item.filterValue)
        : analogDashboard.analogReadings.map((item) => {
            const currentDate = new Date();
            const [hours, minutes] = item.filterValue.split(':').map(Number);

            currentDate.setHours(hours);
            currentDate.setMinutes(minutes);

            const timeZoneOffsetMilliseconds =
              currentDate.getTimezoneOffset() * 60 * 1000;

            const localTime = new Date(
              currentDate.getTime() - timeZoneOffsetMilliseconds
            );

            const localTimeFormatted = localTime.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            });
            return localTimeFormatted;
          });

    const object = {
      name: analogDashboard.parameterName,
      marker: {
        symbol: 'circle',
      },
      data: [],
    };
    for (let i = 0; i < analogDashboard.analogReadings.length; i++) {
      const value =
        Math.round(
          (parseFloat(analogDashboard.analogReadings[i]?.parameterValue) +
            Number.EPSILON) *
            100
        ) / 100;
      object.data.push({
        y: value,
        color:
          value <= parseFloat(analogDashboard?.upperControlLimit) &&
          value >= parseFloat(analogDashboard?.lowerControlLimit)
            ? '#3A9E21'
            : '#DB2A2A',
        lineColor: '#3A9E21',
      });
    }
    chartData.chartData.push(object);
    this.sectionState = SectionStateStatus.Ready;
    this.chartData = { ...chartData };
  }

  onSelectCustomRange() {
    const today = new Date();

    const dialogRef = this.dialog.open(DashboardCalenderComponent, {
      width: window.innerWidth < 850 ? '90%' : '60%',
      height: 'auto',
      data: {
        fromDate: this.isIot
          ? this.iotdashboardSetting.filteredFrom
          : this.dashboardSetting.fromDate,
        toDate: this.isIot
          ? this.iotdashboardSetting.filteredTo
          : this.dashboardSetting.toDate,
        dashboardName: this.dashboard.dashboardName,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.event !== 'cancel') {
        this.dashboardFilter.fromDate = new Date(
          new Date(result?.data?.fromDate).setHours(0, 0, 0, 0)
        ).toISOString();
        this.dashboardFilter.toDate = new Date(
          new Date(result?.data?.toDate).setHours(23, 59, 59, 0)
        ).toISOString();

        if (this.isIot) {
          this.iotdashboardSetting.filteredFrom = result?.data?.fromDate;
          this.iotdashboardSetting.filteredTo = result?.data?.toDate;
          this.iotdashboardSetting.filterName = 'CustomRange';
          this.iotFilterType = 'Day';
          this.updateFilterForIot();
        } else {
          this.dashboardSetting.fromDate = result?.data?.fromDate;
          this.dashboardSetting.toDate = result?.data?.toDate;
          this.dashboardSetting.filterName = 'customRange';
          this.saveFilterData();
        }
      }
    });
  }

  saveFilterData() {
    this.sectionState = SectionStateStatus.Loading;
    const filterData = {
      dashboardName: this.dashboardSetting.dashboardName,
      filterName: this.dashboardSetting.filterName,
      fromDate: this.dashboardFilter.fromDate,
      toDate: this.dashboardFilter.toDate,
    };

    this.reportingDashboardService
      .SaveCustomFilterForDashboard(filterData)
      .subscribe({
        next: (res) => {
          this.getDashboardData();
        },
        error: (error) => {
          this.feedBackService?.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
  }

  getDashboardData() {
    this.sectionState = SectionStateStatus.Loading;
    this.reportingDashboardService
      .getDataByDashboard(
        this.dashboardSetting.dashboardName,
        this.dashboardFilter
      )
      .subscribe({
        next: (res) => {
          this.dashboard = res;
          this.auditPassValue = Number(
            this.dashboard.drilldownChartLevels.level1.find(
              (item) => item.name == 'PASS'
            )?.value
          ).toFixed(2);
          this.optimizedDashboardLevel();
          this.sectionState = SectionStateStatus.Ready;
        },
        error: (error) => {
          this.feedBackService?.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
  }

  optimizedDashboardLevel() {
    let level1 = [];
    let object: any;
    let color: Array<string> = [];
    color = this.dashboardColors[this.dashboard.dashboardName].split(',');

    if (this.dashboard.chartType === 'PieChart') {
      object = {
        colorByPoint: true,
        innerSize: '40%',
        data: [],
      };
      for (
        let i = 0;
        i < this.dashboard.drilldownChartLevels.level1?.length;
        i++
      ) {
        object.data.push({
          name: this.dashboard.drilldownChartLevels.level1[i]?.name,
          y: this.dashboard.drilldownChartLevels.level1[i]?.value
            ? parseFloat(this.dashboard.drilldownChartLevels.level1[i]?.value)
            : 0,
          color: color[i],
          drilldown: this.dashboard.drilldownChartLevels.level1[i]?.id
            ? this.dashboard.drilldownChartLevels.level1[i]?.id
            : '-',
        });
      }
      level1.push(object);
      object = null;
      this.chartData = [...level1];
    } else {
      for (
        let i = 0;
        i < this.dashboard.drilldownChartLevels.level1?.length;
        i++
      ) {
        object = {
          name: this.dashboard.drilldownChartLevels.level1[i]?.name,
          type: this.dashboardTypes[this.dashboard.chartType],
          innerSize: '40%',
          data: [
            {
              y: Number(this.dashboard.drilldownChartLevels.level1[i]?.value),
              drilldown: this.dashboard.drilldownChartLevels.level1[i]?.id,
            },
          ],
          status: this.dashboard.drilldownChartLevels.level1[i]?.status,
          color: color[i],
        };
        level1.push(object);
        object = null;
      }
      this.chartData = [...level1];
    }
  }

  openBottomSheet(): void {
    const bottomSheet = this._bottomSheet.open(DashboardFullscreenComponent, {
      panelClass: 'dashboard-full-screen',
      data: {
        dashboard: this.dashboard,
        dashboardSetting: this.dashboardSetting,
      },
      disableClose: true,
    });

    bottomSheet.backdropClick().subscribe((event) => {
      bottomSheet.instance.handleBackdropClick(event);
    });

    bottomSheet.afterDismissed().subscribe((res) => {
      if (res?.isChanged) {
        this.sectionState = SectionStateStatus.Loading;
        this.dashboardFilter = res.dashboardFilter;
        this.dashboardSetting.fromDate = new Date(res.dashboardFilter.fromDate);
        this.dashboardSetting.toDate = new Date(res.dashboardFilter.toDate);
        this.dashboardSetting.filterName = res.filterName;
        this.getDashboardData();
      }
    });
  }

  openTargetModal() {
    const targetData = {
      targetId: this.dashboard.targetId,
      targetValue: this.dashboard.target,
      originalTarget: this.dashboard.originalTarget,
      trendingType: this.dashboard.dashboardName,
      targetUptoAtLeast: this.dashboard.targetType,
    };
    const dialogRef = this.dialog.open(EditDashboardTargetPopupComponent, {
      width: window.innerWidth < 850 ? '90%' : '60%',
      height: 'auto',
      data: {
        targetData: { ...targetData },
        targetTitle:
          this.translationsList.Dashboards[this.dashboard.dashboardName],
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.updatedData.isUpdate) {
        this.updateTarget(result.updatedData);
      }
    });
  }

  updateTarget(result) {
    if (result?.id) {
      this.sectionState = SectionStateStatus.Loading;
      this.dashboardTargetService.putDashboardTarget(result).subscribe({
        next: async (response) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Success, response?.message)
          );
          this.getDashboardData();
        },
        error: (error) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
    } else {
      this.sectionState = SectionStateStatus.Loading;
      this.dashboardTargetService.postDashboardTarget(result).subscribe({
        next: (response) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Success, response?.message)
          );
          this.getDashboardData();
        },
        error: (error) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
    }
  }

  formatDate(date) {
    return this.datePipe
      .transform(date, 'yyyy-MM-ddT00:00:00.000')
      ?.slice(0, 10);
  }
}
