<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'NavigationBar.ManageTask' | translate">
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <app-state-section
      body
      [flexibleHeight]="true"
      [state]="sectionState"
      [loadingLabel]="loadingLabel | translate"
      [errorLabel]="'Errors.ErrorHappened' | translate"
    >
      <form [formGroup]="workPermitForm">
        <app-dismiss-alert
          *ngIf="data?.status === 'MISSED'"
          [message]="'General.MissedTask' | translate"
        ></app-dismiss-alert>

        <div class="mt-5">
          <app-expandable-panel
            [expanded]="true"
            #expandablePanel
            title="{{ data?.checkList.title }}"
          >
            <div body class="flex column">
              <div style="display: flex; padding: 14px">
                <div formArrayName="sections" style="width: 80%">
                  <ol class="list-style">
                    <li
                      class="rounded p-2.5 mb-2.5 shadow"
                      *ngFor="
                        let sectionCtrl of repeatSectionFormGroup.controls;
                        let i = index
                      "
                    >
                      <div [formGroupName]="i">
                        <div>
                          <h4 class="font-bold">
                            {{ workPermitForm.get("sections").value[i].title }}
                          </h4>
                        </div>
                        <div
                          formArrayName="questions"
                          class="question"
                          [ngSwitch]="
                            workPermitForm.get('sections').value[i].questionType
                          "
                        >
                          <div *ngSwitchCase="'Yes/No'">
                            <div>
                              <ul class="question-type">
                                <li
                                  *ngFor="
                                    let optionCtrl of repeatQuestionsFormGroup(
                                      i
                                    ).controls;
                                    let j = index
                                  "
                                >
                                  <div
                                    class="flex items-start flex-wrap"
                                    [formGroupName]="j"
                                  >
                                    <div class="flex-1">
                                      <div class="flex column items-start my-2">
                                        <h5>
                                          {{
                                            j +
                                              1 +
                                              "- " +
                                              workPermitForm.get("sections")
                                                .value[i].questions[j]
                                                .questionText
                                          }}
                                        </h5>
                                        <div
                                          style="min-width: 100px"
                                          class="flex mt-2"
                                        >
                                          <mat-radio-group
                                            (change)="GetScorePercentage()"
                                            formControlName="userAnswer"
                                            class="mr-4 ml-4"
                                            [disabled]="
                                              data?.isExecuted === true ||
                                              workPermitForm.get('sections')
                                                .value[i].questions[j]
                                                .isNotApplicable
                                            "
                                          >
                                            <mat-radio-button
                                              [style]="
                                                workPermitForm.get('sections')
                                                  .value[i].questions[j]
                                                  .answer === 'Yes'
                                                  ? 'color: #42f548;'
                                                  : 'color: #f54242;'
                                              "
                                              value="Yes"
                                              >{{
                                                "General.Yes" | translate
                                              }}</mat-radio-button
                                            >
                                            <mat-radio-button
                                              [style]="
                                                workPermitForm.get('sections')
                                                  .value[i].questions[j]
                                                  .answer === 'No'
                                                  ? 'color: #42f548;'
                                                  : 'color: #f54242;'
                                              "
                                              class="ml-3"
                                              value="No"
                                              >{{ "General.No" | translate }}
                                            </mat-radio-button>
                                          </mat-radio-group>
                                        </div>
                                      </div>
                                      <mat-checkbox
                                        class="my-2 ml-2"
                                        formControlName="isNotApplicable"
                                        (change)="notApplicableChange()"
                                        color="primary"
                                        [disabled]="data?.isExecuted === true"
                                      >
                                        {{
                                          "ChecklistSection.NotApplicable"
                                            | translate
                                        }}
                                      </mat-checkbox>
                                      <br />
                                      <div class="w-fit">
                                        <ngx-avatars
                                          *ngIf="
                                            workPermitForm.get('sections')
                                              .value[i].questions[j].media
                                          "
                                          class="cursor-pointer"
                                          size="60"
                                          [src]="
                                            getAttachmentImage(
                                              workPermitForm.get('sections')
                                                .value[i].questions[j].media
                                            )
                                          "
                                          [name]="
                                            getAttachementTypeName(
                                              workPermitForm.get('sections')
                                                .value[i].questions[j].media
                                            )
                                          "
                                          [textSizeRatio]="2"
                                          [round]="false"
                                          [initialsSize]="2"
                                          [matTooltip]="
                                            workPermitForm.get('sections')
                                              .value[i].questions[j]?.mediaName
                                          "
                                          [cornerRadius]="10"
                                          [borderColor]="'#1F36B4'"
                                          (clickOnAvatar)="
                                            openBigView(
                                              workPermitForm.get('sections')
                                                .value[i].questions[j].media
                                            )
                                          "
                                        >
                                        </ngx-avatars>
                                      </div>
                                    </div>
                                    <div class="flex-1">
                                      <button
                                        mat-button
                                        color="primary"
                                        (click)="
                                          openMediaModal(
                                            workPermitForm.get('sections')
                                              .value[i].sectionId,
                                            workPermitForm.get('sections')
                                              .value[i].questions[j].questionId
                                          )
                                        "
                                        [disabled]="data?.isExecuted === true"
                                      >
                                        <mat-icon
                                          class="mr-2"
                                          svgIcon="mat:file_upload"
                                        ></mat-icon>
                                        Upload File
                                      </button>
                                      <div class="flex-row">
                                        <mat-label class="field-label">{{
                                          "TaskTracker.View.Description"
                                            | translate
                                        }}</mat-label>
                                        <mat-form-field
                                          class="input-field"
                                          appearance="outline"
                                        >
                                          <textarea
                                            matInput
                                            rows="3"
                                            formControlName="comment"
                                            [placeholder]="
                                              'General.Comment' | translate
                                            "
                                            [readonly]="
                                              data?.isExecuted === true
                                            "
                                          >
                                          </textarea>
                                        </mat-form-field>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div *ngSwitchCase="'Select from range'">
                            <div class="my-2">
                              <ul class="question-type question">
                                <li
                                  *ngFor="
                                    let optionCtrl of repeatQuestionsFormGroup(
                                      i
                                    ).controls;
                                    let j = index
                                  "
                                >
                                  <div
                                    class="flex items-start flex-wrap"
                                    [formGroupName]="j"
                                  >
                                    <div class="flex-1">
                                      <div class="flex column items-start my-2">
                                        <h5>
                                          {{
                                            j +
                                              1 +
                                              "- " +
                                              workPermitForm.get("sections")
                                                .value[i].questions[j]
                                                .questionText
                                          }}
                                        </h5>
                                        <mat-radio-group
                                          [disabled]="
                                            data?.isExecuted === true ||
                                            workPermitForm.get('sections')
                                              .value[i].questions[j]
                                              .isNotApplicable
                                          "
                                          (change)="GetScorePercentage()"
                                          formControlName="userAnswer"
                                          class="ml-4 mr-4"
                                        >
                                          <mat-radio-button
                                            class="ml-3 p-2.5"
                                            *ngFor="let r of ranges"
                                            [style]="'color: #008000;'"
                                            [value]="r.value"
                                          >
                                            {{ r.value }}</mat-radio-button
                                          >
                                        </mat-radio-group>
                                      </div>
                                      <mat-checkbox
                                        class="my-1 ml-2"
                                        formControlName="isNotApplicable"
                                        (change)="notApplicableChange()"
                                        color="primary"
                                        [disabled]="data?.isExecuted === true"
                                      >
                                        {{
                                          "ChecklistSection.NotApplicable"
                                            | translate
                                        }}
                                      </mat-checkbox>
                                      <br />
                                      <div class="w-fit">
                                        <ngx-avatars
                                          *ngIf="
                                            workPermitForm.get('sections')
                                              .value[i].questions[j].media
                                          "
                                          class="cursor-pointer"
                                          size="60"
                                          [src]="
                                            getAttachmentImage(
                                              workPermitForm.get('sections')
                                                .value[i].questions[j].media
                                            )
                                          "
                                          [name]="
                                            getAttachementTypeName(
                                              workPermitForm.get('sections')
                                                .value[i].questions[j].media
                                            )
                                          "
                                          [textSizeRatio]="2"
                                          [round]="false"
                                          [initialsSize]="2"
                                          [matTooltip]="
                                            workPermitForm.get('sections')
                                              .value[i].questions[j]?.mediaName
                                          "
                                          [cornerRadius]="10"
                                          [borderColor]="'#1F36B4'"
                                          (clickOnAvatar)="
                                            openBigView(
                                              workPermitForm.get('sections')
                                                .value[i].questions[j].media
                                            )
                                          "
                                        >
                                        </ngx-avatars>
                                      </div>
                                    </div>
                                    <div class="flex-1">
                                      <button
                                        mat-button
                                        color="primary"
                                        (click)="
                                          openMediaModal(
                                            workPermitForm.get('sections')
                                              .value[i].sectionId,
                                            workPermitForm.get('sections')
                                              .value[i].questions[j].questionId
                                          )
                                        "
                                        [disabled]="data?.isExecuted === true"
                                      >
                                        <mat-icon
                                          class="mr-2"
                                          svgIcon="mat:file_upload"
                                        ></mat-icon>
                                        Upload File
                                      </button>
                                      <div class="flex-row">
                                        <mat-label class="field-label"
                                          >Description</mat-label
                                        >
                                        <mat-form-field
                                          class="input-field"
                                          appearance="outline"
                                        >
                                          <textarea
                                            matInput
                                            rows="3"
                                            formControlName="comment"
                                            [placeholder]="'comment'"
                                            [readonly]="
                                              data?.isExecuted === true
                                            "
                                          >
                                          </textarea>
                                        </mat-form-field>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div *ngSwitchCase="'Machine Parameter'">
                            <div>
                              <ul class="question-type">
                                <li
                                  *ngFor="
                                    let optionCtrl of repeatQuestionsFormGroup(
                                      i
                                    ).controls;
                                    let j = index
                                  "
                                >
                                  <div
                                    class="flex items-start flex-wrap"
                                    [formGroupName]="j"
                                  >
                                    <div class="flex-1">
                                      <div class="flex column items-start my-2">
                                        <h5>
                                          {{
                                            j +
                                              1 +
                                              "- " +
                                              getParamterName(
                                                workPermitForm.get("sections")
                                                  .value[i].questions[j]
                                                  .questionText,
                                                i,
                                                j
                                              )
                                          }}
                                        </h5>
                                        <mat-form-field
                                          class="full-width ml-4 mr-4"
                                          appearance="outline"
                                        >
                                          <input
                                            formControlName="userAnswer"
                                            (input)="GetScorePercentage()"
                                            maxlength="200"
                                            matInput
                                            [placeholder]="'Answer'"
                                            [readonly]="
                                              data?.isExecuted === true ||
                                              workPermitForm.get('sections')
                                                .value[i].questions[j]
                                                .isNotApplicable
                                            "
                                          />
                                        </mat-form-field>
                                      </div>
                                      <mat-checkbox
                                        class="my-1 ml-2"
                                        formControlName="isNotApplicable"
                                        (change)="notApplicableChange()"
                                        color="primary"
                                        [disabled]="data?.isExecuted === true"
                                      >
                                        {{
                                          "ChecklistSection.NotApplicable"
                                            | translate
                                        }}
                                      </mat-checkbox>
                                      <br />
                                      <div class="w-fit">
                                        <ngx-avatars
                                          *ngIf="
                                            workPermitForm.get('sections')
                                              .value[i].questions[j].media
                                          "
                                          class="cursor-pointer"
                                          size="60"
                                          [src]="
                                            getAttachmentImage(
                                              workPermitForm.get('sections')
                                                .value[i].questions[j].media
                                            )
                                          "
                                          [name]="
                                            getAttachementTypeName(
                                              workPermitForm.get('sections')
                                                .value[i].questions[j].media
                                            )
                                          "
                                          [textSizeRatio]="2"
                                          [round]="false"
                                          [initialsSize]="2"
                                          [matTooltip]="
                                            workPermitForm.get('sections')
                                              .value[i].questions[j]?.mediaName
                                          "
                                          [cornerRadius]="10"
                                          [borderColor]="'#1F36B4'"
                                          (clickOnAvatar)="
                                            openBigView(
                                              workPermitForm.get('sections')
                                                .value[i].questions[j].media
                                            )
                                          "
                                        >
                                        </ngx-avatars>
                                      </div>
                                    </div>
                                    <div class="flex-1">
                                      <button
                                        mat-button
                                        color="primary"
                                        (click)="
                                          openMediaModal(
                                            workPermitForm.get('sections')
                                              .value[i].sectionId,
                                            workPermitForm.get('sections')
                                              .value[i].questions[j].questionId
                                          )
                                        "
                                        [disabled]="data?.isExecuted === true"
                                      >
                                        <mat-icon
                                          class="mr-2"
                                          svgIcon="mat:file_upload"
                                        ></mat-icon>
                                        Upload File
                                      </button>
                                      <div class="flex-row">
                                        <mat-label class="field-label"
                                          >Description</mat-label
                                        >
                                        <mat-form-field
                                          class="input-field"
                                          appearance="outline"
                                        >
                                          <textarea
                                            matInput
                                            rows="3"
                                            formControlName="comment"
                                            [placeholder]="'comment'"
                                            [readonly]="
                                              data?.isExecuted === true
                                            "
                                          >
                                          </textarea>
                                        </mat-form-field>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div *ngSwitchCase="'Value Vs Target'">
                            <div>
                              <ul class="question-type">
                                <li
                                  *ngFor="
                                    let optionCtrl of repeatQuestionsFormGroup(
                                      i
                                    ).controls;
                                    let j = index
                                  "
                                >
                                  <div
                                    class="flex items-start flex-wrap"
                                    [formGroupName]="j"
                                  >
                                    <div class="flex-1">
                                      <div class="flex column items-start my-2">
                                        <h5>
                                          {{
                                            j +
                                              1 +
                                              "- " +
                                              workPermitForm.get("sections")
                                                .value[i].questions[j]
                                                .questionText
                                          }}
                                        </h5>
                                        <div
                                          class="flex my-1 justify-center items-center"
                                        >
                                          <h2
                                            class="font-bold text-center mb-5"
                                          >
                                            {{
                                              workPermitForm.get("sections")
                                                .value[i].questions[j].answer
                                            }}
                                          </h2>
                                          <mat-form-field
                                            class="full-width ml-4 mr-4"
                                            appearance="outline"
                                          >
                                            <input
                                              (input)="GetScorePercentage()"
                                              [readonly]="
                                                data?.isExecuted === true ||
                                                workPermitForm.get('sections')
                                                  .value[i].questions[j]
                                                  .isNotApplicable
                                              "
                                              formControlName="userAnswer"
                                              matInput
                                              type="number"
                                              min="0"
                                            />
                                          </mat-form-field>
                                        </div>
                                      </div>
                                      <mat-checkbox
                                        class="my-1 ml-2"
                                        formControlName="isNotApplicable"
                                        (change)="notApplicableChange()"
                                        color="primary"
                                        [disabled]="data?.isExecuted === true"
                                      >
                                        {{
                                          "ChecklistSection.NotApplicable"
                                            | translate
                                        }}
                                      </mat-checkbox>
                                      <br />
                                      <div class="w-fit">
                                        <ngx-avatars
                                          *ngIf="
                                            workPermitForm.get('sections')
                                              .value[i].questions[j].media
                                          "
                                          class="cursor-pointer"
                                          size="60"
                                          [src]="
                                            getAttachmentImage(
                                              workPermitForm.get('sections')
                                                .value[i].questions[j].media
                                            )
                                          "
                                          [name]="
                                            getAttachementTypeName(
                                              workPermitForm.get('sections')
                                                .value[i].questions[j].media
                                            )
                                          "
                                          [textSizeRatio]="2"
                                          [round]="false"
                                          [initialsSize]="2"
                                          [matTooltip]="
                                            workPermitForm.get('sections')
                                              .value[i].questions[j]?.mediaName
                                          "
                                          [cornerRadius]="10"
                                          [borderColor]="'#1F36B4'"
                                          (clickOnAvatar)="
                                            openBigView(
                                              workPermitForm.get('sections')
                                                .value[i].questions[j].media
                                            )
                                          "
                                        >
                                        </ngx-avatars>
                                      </div>
                                    </div>
                                    <div class="flex-1">
                                      <button
                                        mat-button
                                        color="primary"
                                        (click)="
                                          openMediaModal(
                                            workPermitForm.get('sections')
                                              .value[i].sectionId,
                                            workPermitForm.get('sections')
                                              .value[i].questions[j].questionId
                                          )
                                        "
                                        [disabled]="data?.isExecuted === true"
                                      >
                                        <mat-icon
                                          class="mr-2"
                                          svgIcon="mat:file_upload"
                                        ></mat-icon>
                                        Upload File
                                      </button>
                                      <div class="flex-row">
                                        <mat-label class="field-label"
                                          >Description</mat-label
                                        >
                                        <mat-form-field
                                          class="input-field"
                                          appearance="outline"
                                        >
                                          <textarea
                                            matInput
                                            rows="3"
                                            formControlName="comment"
                                            [placeholder]="'comment'"
                                            [readonly]="
                                              data?.isExecuted === true
                                            "
                                          >
                                          </textarea>
                                        </mat-form-field>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ol>
                </div>
                <div style="margin-left: 20px">
                  <ngx-gauge
                    [foregroundColor]="getGuageColors(gaugeValue)"
                    [type]="'full'"
                    [value]="gaugeValue"
                    [label]=""
                    [append]="'%'"
                    [thick]="20"
                  ></ngx-gauge>
                  <h4 class="font-bold text-center">
                    {{ "TaskTracker.ResponseScore" | translate }}
                  </h4>
                </div>
              </div>
            </div>
          </app-expandable-panel>
        </div>
        <div class="flex items-center justify-end my-3">
          <app-basic-button
            [btnType]="'Cancel'"
            [btnText]="'Cancel'"
            (onClick)="goBack()"
          ></app-basic-button>
          <app-basic-button
            *ngIf="!isExecuted && data?.userId === loggedUser?.userId"
            [btnType]="'Add'"
            [btnText]="'Add'"
            [isDisabled]="
              !workPermitForm.valid ||
              data?.checkList.section.length === 0 ||
              data?.status === 'MISSED' ||
              !isWithinRange()
            "
            (onClick)="openProofOfLocationModel()"
          >
          </app-basic-button>
        </div>
      </form>
    </app-state-section>
  </hodhod-page-layout-content>
</hodhod-page-layout>

<ng-template #mediaModal>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">
      {{
        showProofOfLocation
          ? ("ExecutedTask.ProofOfLocation" | translate)
          : ("InspectionPlan.UploadMedia" | translate)
      }}
    </h2>
    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div>
    <form [formGroup]="mediaForm">
      <div class="flex column">
        <file-upload
          formControlName="file"
          [multiple]="false"
          [animation]="true"
          [accept]="showProofOfLocation ? 'image/*' : ''"
        ></file-upload>
      </div>
    </form>
  </div>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
    <app-basic-button
      [btnType]="'Add'"
      [btnText]="'Submit'"
      [isDisabled]="!mediaForm.valid && !showProofOfLocation"
      (onClick)="showProofOfLocation ? executeTask() : uploadAnswerMedia()"
    ></app-basic-button>
  </div>
</ng-template>

<ng-template #imageBigView>
  <div class="flex items-center" mat-dialog-title>
    <img [src]="selectedImg" class="avatar mr-5" />
    <h2 class="headline m-0 flex-auto">{{ data?.title }}</h2>
    <button
      class="text-secondary"
      (click)="downloadDocument(selectedImg)"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:download"></mat-icon>
    </button>

    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div class="flex">
    <div class="">
      <img [src]="selectedImg" [alt]="data?.title" />
    </div>
  </div>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
  </div>
</ng-template>
